import { useEffect } from 'react'
import * as React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Comment from "../components/comments"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { } from "@fancyapps/ui/src/Fancybox/Fancybox.js";

const commentBox = React.createRef()

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data
  useEffect(() => {
    const scriptEl = document.createElement('script')
    scriptEl.async = true
    scriptEl.src = 'https://utteranc.es/client.js'
    scriptEl.setAttribute('repo', 'bozworks/comments.onurboz.com')
    scriptEl.setAttribute('issue-term', 'title')
    scriptEl.setAttribute('id', 'onurbozdotcom')
    scriptEl.setAttribute('label', 'Comment')
    scriptEl.setAttribute('theme', 'github-dark')
    scriptEl.setAttribute('crossorigin', 'anonymous')
    if (commentBox && commentBox.current) {
      commentBox.current.appendChild(scriptEl)
    } else {
      console.log(`Error adding utterances comments on: ${commentBox}`)
    }
  }, [])

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <div className="container container-max-md">
        <article
          className="blog-post"
          itemScope
          itemType="http://schema.org/Article"
          lang={post.frontmatter.lang}
        >
          <header className="mb-5">
            <h1 className="h3" itemProp="headline">{post.frontmatter.title}</h1>
            <p className="text-muted mt-n4"><small>{post.frontmatter.date}</small></p>
            <h2 className="h5 text-400" itemProp="headline">{post.frontmatter.description}</h2>
          </header>
          <section
            dangerouslySetInnerHTML={{ __html: post.html }}
            itemProp="articleBody"
            className="entry"
          />
          <footer>
            <Link title="Go to about page" className="link-light" to="/about">
              <Bio />
            </Link>
            <h5 className="h5 text-500 mt-5">Leave a comment</h5>
            <div className="bg-800 p-4 rounded-3">
              {<Comment commentBox={commentBox} />}
            </div>
          </footer>
        </article>
        <nav className="blog-post-nav mt-5">
          <ul
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: 0,
            }}
          >
            <li>
              {previous && (
                <Link to={previous.fields.slug} rel="prev">
                  ← {previous.frontmatter.title}
                </Link>
              )}
            </li>
            <li>
              {next && (
                <Link to={next.fields.slug} rel="next">
                  {next.frontmatter.title} →
                </Link>
              )}
            </li>
          </ul>
        </nav>
      </div>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query allBlogPosts(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        lang
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
